import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  computed: {
    currentLayerId: get('layers/currentLayer@id'),
    scadaLayerId: get('admin/modulesMapping@scada.layer_id'),
    scadaDataSource: get('admin/modulesMapping@scada.datasource_name'),
    scadaLayer: get('layers/featuresLayers@:scadaLayerId'),
    scadaFeatures: get('layers/layersFeatures@:scadaLayerId'),
    layersFilters: get('layers/layersFilters'),
    scadaInterval: get('admin/settingsValues@scada_module_measurements_refresh_interval'),
    isScadaAdminEnabled: get('admin/settingsValues@scada_module_enabled'),
    isScadaSuperAdminEnabled: get('admin/additionalModules@SCADA.enabled'),
    isScadaEnabled() {
      return this.isScadaAdminEnabled && this.isScadaSuperAdminEnabled;
    },
    scadaThreeInterval: get('admin/settingsValues@scada_3_module_measurements_refresh_interval'),
    scadaThreeRelationDatasources: get('admin/modulesMapping@scada3.relation_datasources'),
    isScadaThreeAdminEnabled: get('admin/settingsValues@scada_3_module_enabled'),
    isScadaThreeSuperAdminEnabled: get('admin/additionalModules@SCADA3.enabled'),
    isScadaThreeConfigured: get('admin/additionalModules@SCADA3.configured'),
    isScadaThreeEnabled() {
      return this.isScadaThreeAdminEnabled && this.isScadaThreeSuperAdminEnabled && this.isScadaThreeConfigured;
    },
  },
  data: () => ({
    scadaThreeRelationDatasourcesInterval: undefined,
  }),
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    getFeaturesLayer: call('layers/getFeaturesLayer'),
    setCurrentStyle: call('styles/setCurrentStyle'),
    setDefaultStyle: call('styles/setDefaultStyle'),
    setLoaded: call('modules/setLoaded'),
    updateMeasurementsAttributesValues: call('scada/updateMeasurementsAttributesValues'),
    updateScadaThreeMeasurements: call('scada/updateScadaThreeMeasurements'),
    async scadaThreeRelationDatasourcesIntervalAction(first = false) {
      const r = await this.updateScadaThreeMeasurements();
      if (first) return;
      this.projectLayers.forEach(layer => {
        if (r.updated_datasources.includes(layer.data_source_name)) {
          this.refreshMvtSource(layer.id);
          if (this.currentLayerId === layer.id) {
            this.$root.$emit('refreshTableData');
            this.$root.$emit('refreshFormData');
            this.$root.$emit('refreshScadaWidget');
            this.$root.$emit('updateLegendStylesCounts', { layersIds: [layer.id] });
          }
        }
      });
    },
    async refreshScada(layer) {
      await this.updateMeasurementsAttributesValues();
      this.refreshMvtSource(layer.id);
      if (this.currentLayerId === layer.id) {
        this.$root.$emit('refreshTableData');
        this.$root.$emit('refreshFormData');
        this.$root.$emit('refreshScadaWidget');
        this.$root.$emit('updateLegendStylesCounts', { layersIds: [layer.id] });
      }
    },
    async toggleScada(value) {
      if (value) {
        await Promise.all([
          this.getFeaturesLayer(this.scadaLayerId),
          this.getLayerFeatures({ layer_id: this.scadaLayerId }),
        ]);
        this.addScadaLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'scadaInfo' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        this.removeProjectLayers([this.scadaLayerId]);
        if (this.layersFilters[this.scadaLayerId]) {
          delete this.layersFilters[this.scadaLayerId];
        }
        this.setLoaded({ dataSource: this.scadaDataSource, value: false });
      }
    },
    toggleScadaFilter() {
      this.removeProjectLayers([this.scadaLayerId]);
      this.addScadaLayer();
    },
    addScadaLayer() {
      this.removeProjectLayers([this.scadaLayerId]);
      const { data_source_name, id, type, group_id, name } = this.scadaLayer;
      const style = JSON.parse(JSON.stringify(this.scadaLayer.style_web));

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,

          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.scadaLayer.id]
                ? this.layersFilters[this.scadaLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, this.scadaLayer.geometry_type, true, id),
        renderMode: 'hybrid',
      });
      const defaultStyle = {
        datasource: this.scadaDataSource,
        name: this.$i18n.t('default.defaultStyle'),
        isDefault: true,
        style: style,
      };
      this.setCurrentStyle({ dataSource: this.scadaDataSource, style: defaultStyle });
      this.setDefaultStyle({ dataSource: this.scadaDataSource, style: defaultStyle });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      this.setLoaded({ dataSource: this.scadaDataSource, value: true });
    },
    async setScadaStyle(style) {
      const { id, geometry_type } = this.scadaLayer;
      const layersArray = this.getLayerById('layers').getLayers().getArray();
      const layer = layersArray.find(layer => layer.get('id') === this.scadaLayerId);
      layer.setStyle(f => this.getFeatureStyle(f, style.style, geometry_type, false, id));
    },
  },
  mounted() {
    this.$root.$on('scadaStyleChanged', this.setScadaStyle);
  },
};
