<template>
  <div>
    <base-button-subheader :subheader-translation-path="subtitleTranslationPath">
      <component
        v-if="simple"
        :is="rounded ? 'BaseRoundedButton' : 'BaseButton'"
        class="mr-0"
        @click="activeType ? toggleOff() : toggleOn('map')"
        :straight-text="title"
        :button-style="buttonStyle"
        :loading="loading"
        v-bind="buttonBind"
      />
      <dots-menu
        v-else
        :items="dotsItems"
        v-on="{ ...computedDotsItemsCustomListeners }"
        @turnOnAddingByMap="toggleOn('map')"
        @turnOnAddingByIds="toggleOn('ids')"
      >
        <template #activator="{ on }">
          <component
            :is="rounded ? 'BaseRoundedButton' : 'BaseButton'"
            class="mr-0"
            v-on="getButtonListeners(on)"
            :straight-text="title"
            :button-style="buttonStyle"
            :loading="loading"
            v-bind="buttonBind"
          />
        </template>
      </dots-menu>
    </base-button-subheader>
    <main-input-dialog
      :is-visible.sync="computedIsDialogVisible"
      :loading="loading"
      :title-translation="$i18n.t('dialog.assignObject')"
      agree-text="dialog.agreeAssign"
      dialog-width="30%"
      @agree="pushFeatures([newByIdsFeature])"
      :inputs="dialogInputs"
    >
      <template #[`dataSourceName.input`]="data">
        <v-col cols="6">
          <data-input v-model="newByIdsFeature['dataSource']" v-bind="data" @change="updateAllowedFeatures" />
        </v-col>
      </template>
      <template #[`featureId.input`]="data">
        <v-col cols="6">
          <data-input v-model="newByIdsFeature['id']" v-bind="data" />
        </v-col>
      </template>
    </main-input-dialog>
  </div>
</template>
<script>
import { call, get } from 'vuex-pathify';
import DotsMenu from '@/components/DotsMenu';
import MainInputDialog from '@/components/MainInputDialog';
export default {
  name: 'DataInputFeatures',
  components: {
    DotsMenu,
    MainInputDialog,
  },
  props: {
    buttonProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    subtitleTranslationPath: {
      type: String,
      default: '',
    },
    translationPath: {
      type: String,
      default: 'dialog.assignObject',
    },
    translationStraight: {
      type: String,
      default: '',
    },
    dotsItems: {
      type: Array,
      default: () => [{ name: 'turnOnAddingByMap' }, { name: 'turnOnAddingByIds' }],
    },
    dotsItemsCustomListeners: {
      type: Object,
      default: () => {
        return {};
      },
    },
    featuresSettings: {
      type: Object,
      /*
      Decides which layers and data sources are considered in the filter
      Empty or undefined list means no filter
      So only completed lists required
      Takes an object in a structure:
      {
        layers: {
          included: undefined,
          excluded: [1, 2],
        },
        dataSources: {
          included: ['dataSource1', 'dataSource2'],
          excluded: [],
        },
      }
      */
    },
    simple: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    additionalAttributes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    activeTool: get('tools/activeTool'),
    dataSources: get('layers/metadata'),
    layers: get('layers/layers'),
    buttonBind() {
      const { disabled } = this;
      return {
        ...this.buttonProps,
        disabled,
        ...(this.rounded && this.activeType
          ? { color: this.$_colors.primarybackground, iconColor: this.$_colors.primary }
          : {}),
      };
    },
    buttonStyle() {
      if (this.buttonProps.isIcon) {
        return { iconColor: this.activeType ? this.$_colors.primary : 'rgba(0, 0, 0, 0.8)' };
      }
      return this.activeType
        ? {
            backgroundColor: `${this.$_colors.primarybackground} !important`,
            border: '1px solid rgba(26, 115, 232, 0.3) !important',
            color: this.$_colors.primary,
          }
        : {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            border: '1px solid rgba(0, 0, 0, 0.2) !important',
            color: '#61646d',
          };
    },
    computedDotsItemsCustomListeners() {
      return Object.fromEntries(
        Object.entries(this.dotsItemsCustomListeners).map(([key, value]) => {
          return [
            key,
            () => {
              value.handler();
              if (!value.withoutInteraction) this.toggleOn(value.toggleType || 'map');
            },
          ];
        })
      );
    },
    computedFeaturesSettings() {
      const layersIncluded = this.featuresSettings?.layers?.included || [];
      const layersExcluded = this.featuresSettings?.layers?.excluded || [];
      const dataSourcesIncluded = this.featuresSettings?.dataSources?.included || [];
      const dataSourcesExcluded = this.featuresSettings?.dataSources?.excluded || [];
      return {
        layers: {
          included: layersIncluded,
          excluded: layersExcluded,
        },
        dataSources: {
          included: dataSourcesIncluded,
          excluded: dataSourcesExcluded,
        },
      };
    },
    computedIsDialogVisible: {
      get() {
        return this.activeType === 'ids';
      },
      set() {
        this.toggleOff();
      },
    },
    dataSourcesArray() {
      return this.$_objectToArray(this.dataSources).filter(dataSource => {
        return (
          this.computedFeaturesSettings.dataSources.included.includes(dataSource.name) &&
          !this.computedFeaturesSettings.dataSources.excluded.includes(dataSource.name)
        );
      });
    },
    dialogInputs() {
      return [
        {
          name: 'dataSourceName',
          translationPath: 'dialog.dataSource',
          dataType: {
            name: 'select',
          },
          items: this.dataSourcesArray,
          itemValue: 'name',
          itemText: 'verbose_name',
          cols: '12',
          rules: [v => !!v || v == '0' || 'rules.required'],
        },
        {
          name: 'featureId',
          straightTitle: this.dataSourceDescAttributeVerboseName,
          dataType: {
            name: 'select',
          },
          items: this.dataSourceFeaturesIds,
          itemValue: 'id',
          itemText: 'desc',
          disabled: !this.newByIdsFeature['dataSource'] || this.featureIdLoading,
          loading: this.featureIdLoading,
          cols: '12',
          rules: [v => !!v || v == '0' || 'rules.required'],
        },
      ];
    },
    title() {
      return this.translationStraight || this.$i18n.t(this.translationPath) || '';
    },
  },
  data: () => ({
    activeType: undefined,
    dataSourceDescAttributeVerboseName: 'ID',
    dataSourceFeaturesIds: [],
    dataSourceIdAttributeName: '',
    featureIdLoading: false,
    isDialogLoading: false,
    newByIdsFeature: {},
  }),
  methods: {
    getDataSourceFeatures: call('layers/getDataSourceFeatures'),
    getButtonListeners(on) {
      return this.activeType
        ? {
            click: this.toggleOff,
          }
        : on;
    },
    toggleOn(type) {
      this.activeType = type;
      this.$emit('status', type);
    },
    toggleOff() {
      this.activeType = undefined;
      this.$root.$emit('identification-action', false);
      this.$emit('status', undefined);
    },
    pushFeatures(features) {
      this.$emit('pushFeatures', features);
    },
    async updateAllowedFeatures(dataSourceName) {
      const attributesSchema = this.dataSources[dataSourceName].attributes_schema;
      const descAttributeName = attributesSchema.desc_attribute_name || attributesSchema.id_name;
      this.dataSourceIdAttributeName = attributesSchema.id_name;
      this.dataSourceDescAttributeVerboseName =
        attributesSchema.attributes.find(attribute => attribute.name === descAttributeName).verbose_name || 'ID';
      this.featureIdLoading = true;
      try {
        this.dataSourceFeaturesIds = await this.getDataSourceFeatures({
          dataSource: dataSourceName,
          idsDescsOnly: true,
        });
        this.featureIdLoading = false;
      } catch {
        this.featureIdLoading = false;
      }
    },
  },
  watch: {
    activeType(nV) {
      if (nV === 'map') {
        this.$root.$off('assignedObjects');
        this.$root.$on('assignedObjects', identifiedFeatures => {
          if (this.disabled || this.loading) {
            return;
          }
          const mappedFeatures = identifiedFeatures.map(element => {
            return element.features;
          });
          let featuresToAdd = [];
          for (const features of mappedFeatures) {
            featuresToAdd = featuresToAdd.concat(features.filter(feature => feature.dataSource));
          }
          const filteredFeatures = featuresToAdd.filter(feature => {
            const boolArray = [
              this.computedFeaturesSettings.layers.included.length < 1 ||
                this.computedFeaturesSettings.layers.included.includes(feature.layerId),
              this.computedFeaturesSettings.dataSources.included.length < 1 ||
                this.computedFeaturesSettings.dataSources.included.includes(feature.dataSource),
              this.computedFeaturesSettings.layers.excluded.length < 1 ||
                !this.computedFeaturesSettings.layers.excluded.includes(feature.layerId),
              this.computedFeaturesSettings.dataSources.excluded.length < 1 ||
                !this.computedFeaturesSettings.dataSources.excluded.includes(feature.dataSource),
            ];
            return boolArray.every(e => e);
          });
          this.pushFeatures(filteredFeatures);
        });

        this.$root.$emit('identification-action', true, {
          specialType: 'addByMap',
          additionalAttributes: this.additionalAttributes,
          ...((this.computedFeaturesSettings?.layers.included.length > 0 ||
            this.computedFeaturesSettings?.layers.excluded.length > 0) && {
            layersFilter: layer =>
              [
                this.computedFeaturesSettings.layers.included.length < 1 ||
                  this.computedFeaturesSettings.layers.included.includes(layer.get('id')),
                this.computedFeaturesSettings.layers.excluded.length < 1 ||
                  !this.computedFeaturesSettings.layers.excluded.includes(layer.get('id')),
              ].every(e => e),
          }),
        });
      }
    },
    activeTool(nV, oV) {
      if (nV !== oV && oV === 'identification-addByMap') {
        this.activeType = undefined;
      }
    },
    computedIsDialogVisible(nV) {
      this.dataSourceDescAttributeVerboseName = 'ID';
      if (nV) {
        this.unwatchDataSources = this.$watch(
          vm => vm.dataSources,
          nV => {
            if (!nV[this.newByIdsFeature.dataSource]) {
              this.newByIdsFeature.dataSource = '';
            }
          },
          {
            deep: true,
          }
        );
      } else if (this.unwatchDataSources) {
        this.unwatchDataSources();
      }
    },
    'newByIdsFeature.dataSource'(nV) {
      if (!nV) {
        this.newByIdsFeature.id = null;
      }
    },
  },
  beforeDestroy() {
    if (this.activeType) this.toggleOff();
  },
};
</script>
