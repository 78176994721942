import { get, sync } from 'vuex-pathify';
import locks from '@/mixins/locks';

import { MVT, GeoJSON } from 'ol/format';
import { Vector as VectorLayer, VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource, Vector as VectorSource } from 'ol/source';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  mixins: [locks],
  computed: {
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    cutoffResult: sync('locks/cutoffResult'),
    excludesValves: sync('locks/excludesValves'),
    locksAnalysisLayersIds: get('admin/modulesMapping@failures.cutoff_analysis_layers_ids'),
    resultLayers: sync('locks/resultLayers'),
    valvesLayer: get('layers/featuresLayers@:valvesLayerId'),
    valvesMetadata: get('layers/metadata@:valvesDataSource'),
    valvesStateAttribute: get('admin/modulesMapping@failures.locks_state_attribute'),
    valvesToClose: sync('locks/valvesToClose'),
    valvesLayerId() {
      return this.locksAnalysisLayersIds?.['water_valves'];
    },
    valvesDataSource() {
      return this.valvesLayer.data_source_name;
    },
  },
  methods: {
    addValvesLayer() {
      this.removeProjectLayers([this.valvesLayerId]);
      const { data_source_name, id, type, group_id, name } = this.valvesLayer;
      const stateAttribute = this.valvesMetadata.attributes_schema.attributes.find(
        att => att.name === this.valvesStateAttribute
      );
      const valuesList = stateAttribute.allowed_values.map(value => {
        let style = {};
        if (value.toLowerCase().includes('otwart')) {
          style = JSON.parse(JSON.stringify(this.valvesStateStyles.find(state => state.text === 'open')));
        } else if (value.toLowerCase().includes('zamkn')) {
          style = JSON.parse(JSON.stringify(this.valvesStateStyles.find(state => state.text === 'close')));
        } else {
          style = JSON.parse(JSON.stringify(this.valvesStateStyles.find(state => state.text === 'noData')));
        }
        delete style.text;
        return { value, ...style };
      });
      const values = {};
      for (const value of valuesList) {
        values[value.value] = value;
      }
      const nullStyle = JSON.parse(JSON.stringify(this.valvesStateStyles.find(state => state.text === 'noData')));
      delete nullStyle.text;
      values[null] = { value: null, ...nullStyle };
      const style = {
        'circle-color': '#c7c7c7',
        'circle-radius': 3,
        'fill-opactity': 1,
        labels: [],
        uniques: {
          property: this.valvesStateAttribute,
          values,
        },
      };
      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,

          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, { filters: {}, styleAttributes }, true);
          },
        }),
        zIndex: 1000,
        style: f => this.getFeatureStyle(f, style, this.valvesLayer.geometry_type, true, id),
        renderMode: 'hybrid',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('locksLayers').getLayers().push(mvtLayer);
    },
    deleteLocksLayers() {
      const locksLayers = this.getLayerById('locksLayers').getLayers();
      if (locksLayers) {
        locksLayers.clear();
      }
      const excludedLocks = this.getLayerById('excludedLocks');
      if (excludedLocks) {
        excludedLocks.getSource().clear();
      }
      const valvesProjectLayer = this.projectLayers.find(layer => layer.id === this.valvesLayerId);
      if (valvesProjectLayer) {
        this.$root.$emit('pushProjectLayers', [valvesProjectLayer]);
      } else {
        this.map.updateSize();
      }
      this.excludesValves = [];
    },
    deleteExcludedLocksFeatures() {
      const layer = this.getLayerById('excludedLocks');
      if (layer) {
        layer.getSource().clear();
      }
      this.excludesValves = [];
    },
    deleteExcludedLocksFeature(featureId) {
      this.$root.$emit('deleteFeature', 'excludedLocks', featureId);
      this.excludesValves = this.excludesValves.filter(valve => valve.id != featureId);
    },
    async addExcludedLocksFeature(featureId) {
      if (!this.getLayerById('excludedLocks')) {
        const excludedStyle = this.valvesStateStyles.find(state => state.text === 'excluded');
        this.map.addLayer(
          new VectorLayer({
            id: 'excludedLocks',
            source: new VectorSource({}),
            style: f => this.getFeatureStyle(f, excludedStyle, 'point', false, 'excludedLocks'),
            zIndex: 999,
          })
        );
      }
      const layer = this.getLayerById('excludedLocks');
      const feature = await this.getLayerFeature({ layer_id: this.valvesLayerId, feature_id: featureId });
      const geojsonFeature = new GeoJSON().readFeature(feature, {
        dataProjection: feature.crs.properties.name,
        featureProjection: this.$_config.defaultEpsg,
      });
      layer.getSource().addFeature(geojsonFeature);
      const featureProperties = { ...feature.properties, id: feature.id };
      this.excludesValves = this.excludesValves.concat([featureProperties]);
    },
    async toggleValvesLayer(value) {
      if (value) {
        await this.getFeaturesLayer(this.valvesLayerId);
        this.addValvesLayer();
        this.$root.$emit('locksAnalysisValvesFetched');
        // this.toggleModuleIdentification({ isActive: true, moduleRoute: 'locksAnalysisLock' });
      } else {
        // this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        this.map.removeLayer(this.getLayerById(this.valvesLayerId));
      }
    },
    deleteLocksResultLayer(layerId) {
      const layersArray = this.getLayerById('locksResultLayers').getLayers().getArray();
      const layersIds = layersArray.map(l => l.get('id'));
      const index = layersIds.indexOf(layerId);
      if (index >= 0) {
        layersArray.splice(index, 1);
        this.map.updateSize();
      }
    },
    toggleLocksResultLayers(value) {
      if (!value) {
        const layer = this.getLayerById('locksResultLayers').getLayers();
        if (layer) {
          layer.clear();
        }
        this.resultLayers = [];
        return;
      }
      for (const layer of this.resultLayers) {
        this.deleteLocksResultLayer(layer.id);
        this.toggleLocksResultLayer(layer);
      }
    },
    async toggleLocksResultLayer(layer) {
      const { data_source_name, id, type, group_id, name, style, opacity, visible, geometry_type } = layer;
      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isCutoff: true,
        visible,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            const filter = {
              $IN: {
                [`${layer.data_source_name}.id`]: this.cutoffResult[layer.locksKey],
              },
            };
            defaultMvtSourceLoader(
              tile,
              id,
              {
                filters: filter,
                styleAttributes,
              },
              true
            );
          },
        }),
        zIndex: 999,
        style: f => this.getFeatureStyle(f, style, geometry_type, false, id),
        renderMode: 'hybrid',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('locksResultLayers').getLayers().push(mvtLayer);
    },
    async toggleValvesToCloseLayer(value) {
      if (!value) {
        const layer = this.getLayerById('valvesToClose');
        if (layer) {
          layer.getSource().clear();
        }
        this.valvesToClose = [];
        return;
      }
      if (!this.getLayerById('valvesToClose')) {
        const excludedStyle = this.valvesStateStyles.find(state => state.text === 'toClose');
        this.map.addLayer(
          new VectorLayer({
            id: 'valvesToClose',
            source: new VectorSource({}),
            style: f => this.getFeatureStyle(f, excludedStyle, 'point', false, 'valvesToClose'),
            zIndex: 999,
          })
        );
      }
      const layer = this.getLayerById('valvesToClose');
      layer.getSource().clear();
      const filter = {
        $IN: {
          [this.valvesMetadata.attributes_use_datasource_qualified_names
            ? this.valvesMetadata.attributes_schema.id_name
            : `${this.valvesDataSource}.${this.valvesMetadata.attributes_schema.id_name}`]:
            this.cutoffResult.water_valves,
        },
      };
      const r = await this.getLayerFeatures({
        layer_id: this.valvesLayerId,
        features_filter: filter,
      });
      const features = r.data.data.features;
      const geojsonFeatures = new GeoJSON().readFeatures(features, {
        dataProjection: features.crs.properties.name,
        featureProjection: this.$_config.defaultEpsg,
      });
      layer.getSource().addFeatures(geojsonFeatures);
      const featuresProperties = features.features.map(feature => {
        return { ...feature.properties, id: feature.id };
      });
      this.valvesToClose = featuresProperties;
      this.$root.$emit('cutoffResultFetched');
    },
    toggleDeaerationHydrants({ features, style } = {}) {
      this.getLayerById('locks-analysis-deaeration')?.getSource()?.clear();
      if (!features || features?.features?.length === 0) return;
      if (!this.getLayerById('locks-analysis-deaeration')) {
        const newLayer = new VectorLayer({
          id: 'locks-analysis-deaeration',
          isSpecial: true,
          zIndex: 999,
          opacity: 1,
          source: new VectorSource(),
        });
        this.map.addLayer(newLayer);
      }
      this.getLayerById('locks-analysis-deaeration')
        .getSource()
        .addFeatures(
          new GeoJSON().readFeatures(features, {
            featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
            dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          })
        );
      this.getLayerById('locks-analysis-deaeration').setStyle(f =>
        this.getFeatureStyle(f, style, 'point', true, 'locks-analysis-deaeration')
      );
    },
  },
  mounted() {
    this.$root.$off('toggleValvesLayer');
    this.$root.$on('toggleValvesLayer', this.toggleValvesLayer);
    this.$root.$off('addExcludedLocksFeature');
    this.$root.$on('addExcludedLocksFeature', this.addExcludedLocksFeature);
    this.$root.$off('deleteLocksLayers');
    this.$root.$on('deleteLocksLayers', this.deleteLocksLayers);
    this.$root.$off('deleteExcludedValve');
    this.$root.$on('deleteExcludedLocksFeature', this.deleteExcludedLocksFeature);
    this.$root.$off('deleteExcludedLocksFeatures');
    this.$root.$on('deleteExcludedLocksFeatures', this.deleteExcludedLocksFeatures);
    this.$root.$off('toggleValvesToCloseLayer');
    this.$root.$on('toggleValvesToCloseLayer', this.toggleValvesToCloseLayer);
    this.$root.$off('toggleLocksResultLayers');
    this.$root.$on('toggleLocksResultLayers', this.toggleLocksResultLayers);
    this.$root.$off('toggleDeaerationHydrants');
    this.$root.$on('toggleDeaerationHydrants', this.toggleDeaerationHydrants);
  },
};
