import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';
import { scaleLinear } from 'd3-scale';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  data: () => ({
    isDmaZonesChoroplethMap: false,
  }),
  computed: {
    dmaDataSource: get('admin/modulesMapping@billing.dma_datasource_name'),
    dmaLayerId: get('admin/modulesMapping@billing.dma_layer_id'),
    dmaLayer: get('layers/featuresLayers@:dmaLayerId'),
    dmaFeatures: get('layers/layersFeatures@:dmaLayerId'),
    layersFilters: get('layers/layersFilters'),
    partitionByDma: get('billings/partitionByDma'),
    dmaZonesChoroplethMapValues() {
      const values = {};
      for (const partition of this.$_objectToArray(this.partitionByDma)) {
        values[partition.dma_id] = partition.day_average;
      }
      return values;
    },
    dmaZonesChoroplethMapValuesMax() {
      return Math.max(...this.$_objectToArray(this.dmaZonesChoroplethMapValues));
    },
    dmaZonesChoroplethMapValuesMin() {
      return Math.min(...this.$_objectToArray(this.dmaZonesChoroplethMapValues));
    },
  },
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    async toggleDmaLayer(value) {
      if (value) {
        await Promise.all([
          this.getFeaturesLayer(this.dmaLayerId),
          this.getLayerFeatures({ layer_id: this.dmaLayerId }),
        ]);
        this.addDmaLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'waterDissolutionDmaInfo' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        this.removeProjectLayers([this.dmaLayerId]);
        if (this.layersFilters[this.dmaLayerId]) {
          delete this.layersFilters[this.dmaLayerId];
        }
      }
    },
    toggleDmaFilter() {
      this.removeProjectLayers([this.dmaLayerId]);
      this.addDmaLayer();
    },
    addDmaLayer() {
      this.removeProjectLayers([this.dmaLayerId]);
      const { data_source_name, id, type, group_id, name } = this.dmaLayer;
      const style = JSON.parse(JSON.stringify(this.dmaLayer.style_web));
      style.minzoom = 1;

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.dmaLayer.id]
                ? this.layersFilters[this.dmaLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, this.dmaLayer.geometry_type, true, id),
        renderMode: 'hybrid',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      if (this.isDmaZonesChoroplethMap) {
        this.toggleDmaZonesChoroplethMap(true);
      }
    },
    getdmaZonesChoroplethRanges(numberOfClasses = 5, rampColor = 'red', rampColors = []) {
      let colors = [];
      if (rampColors && rampColors.length > 0) {
        numberOfClasses = rampColors.length;
        const classesNumbers = Array.from({ length: 5 }, (v, k) => k + 2);
        colors = scaleLinear().domain(classesNumbers).range(rampColors);
      } else {
        colors = scaleLinear()
          .domain([1, numberOfClasses + 1])
          .range(['white', rampColor]);
      }
      const values = scaleLinear()
        .domain([1, numberOfClasses + 1])
        .range([this.dmaZonesChoroplethMapValuesMin, this.dmaZonesChoroplethMapValuesMax]);
      const rangesColors = [];
      for (let idx = 2; idx < numberOfClasses + 2; idx++) {
        rangesColors.push(colors(idx));
      }
      const ranges = [];
      for (let idx = 2; idx < numberOfClasses + 1; idx++) {
        const roundValue = Math.round(values(idx) * 100) / 100;
        ranges.push(roundValue);
      }
      return { ranges, rangesColors };
    },
    toggleDmaZonesChoroplethMap(value) {
      const customColorsRamp = ['#FEF0D9', '#FDCC8A', '#FC8D59', '#E34A33', '#B30000'];
      const { ranges, rangesColors } = this.getdmaZonesChoroplethRanges(5, 'red', customColorsRamp);
      if (value) {
        const data = {
          ranges,
          rangesColors,
          values: this.dmaZonesChoroplethMapValues,
        };
        this.getLayerById(this.dmaLayerId, 'layers').setStyle(feature => {
          return this.getChoroplethMapFeatureStyle(feature, data, this.dmaLayerId);
        });
        this.$root.$emit('updateDmaZonesChoroplethMapLegend', data);
      } else {
        const layer = this.getLayerById(this.dmaLayerId, 'layers');
        if (!this.dmaLayer || !layer) {
          this.isDmaZonesChoroplethMap = false;
          return;
        }
        const style = JSON.parse(JSON.stringify(this.dmaLayer.style_web));
        style.minzoom = 1;
        layer.setStyle(feature => {
          return this.getFeatureStyle(feature, style, this.dmaLayer.geometry_type, true, this.dmaLayerId);
        });
      }
      this.isDmaZonesChoroplethMap = value;
    },
  },
  mounted() {
    this.$root.$on('toggleDmaLayer', this.toggleDmaLayer);
    this.$root.$on('toggleDmaFilter', this.toggleDmaFilter);
    this.$root.$on('toggleDmaZonesChoroplethMap', this.toggleDmaZonesChoroplethMap);
  },
};
