<template>
  <v-col cols="auto" class="pa-1">
    <v-badge
      overlap
      :content="count"
      :value="count && count != 0"
      color="error"
      bordered
      :offset-y="15"
      :offset-x="15"
      style="z-index: 10"
    >
      <base-rounded-button
        class="pa-4"
        color="rgba(235,235,235,0.5)"
        :icon-color="$_colors.iconIdle"
        size="x-small"
        icon="mdi-speedometer"
        icon-size="20"
        has-border
        :tooltip="$i18n.t('sidebar.unassignedWatermeters.buttonTooltip')"
        @click="open"
      />
    </v-badge>
  </v-col>
</template>
<script>
import { call, get } from 'vuex-pathify';
export default {
  name: 'UnassignedWatermetersButton',
  computed: {
    projectId: get('layers/projectId'),
    count: get('billings/unassignedWatermetersCount'),
    layerId: get(
      'admin/modulesMapping@unassigned_watermeters.unassigned_watermeters_partitions_datasource.settings.layer_id'
    ),
  },
  methods: {
    getUnassignedWatermetersCount: call('billings/getUnassignedWatermetersCount'),
    async init() {
      await this.getUnassignedWatermetersCount();
    },
    open() {
      if (this.$route.name === 'unassignedWatermeters') return;
      this.$router.push({
        name: 'unassignedWatermeters',
        params: { lid: this.layerId },
        query: { project: this.projectId },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
