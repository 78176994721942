import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  computed: {
    investmentsLayerId: get('admin/modulesMapping@investments.layer_id'),
    investmentsDataSource: get('admin/modulesMapping@investments.datasource_name'),
    investmentsLayer: get('layers/featuresLayers@:investmentsLayerId'),
    investmentsFeatures: get('layers/layersFeatures@:investmentsLayerId'),
    layersFilters: get('layers/layersFilters'),
  },
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    setCurrentStyle: call('styles/setCurrentStyle'),
    setDefaultStyle: call('styles/setDefaultStyle'),
    setLoaded: call('modules/setLoaded'),

    async toggleInvestments(value) {
      if (value) {
        await Promise.all([this.getLayerFeatures({ layer_id: this.investmentsLayerId })]);
        this.addInvestmentsLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'investment' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        if (!this.projectLayers.find(layer => layer.id === this.investmentsLayerId)) {
          this.removeProjectLayers([this.investmentsLayerId]);
        }
        if (this.layersFilters[this.investmentsLayerId]) {
          delete this.layersFilters[this.investmentsLayerId];
        }
        this.setLoaded({ dataSource: this.investmentsDataSource, value: false });
      }
    },
    toggleInvestmentsFilter() {
      this.removeProjectLayers([this.investmentsLayerId]);
      this.addInvestmentsLayer();
    },
    addInvestmentsLayer() {
      this.removeProjectLayers([this.investmentsLayerId]);
      const { data_source_name, id, type, group_id, name } = this.investmentsLayer;
      const style = JSON.parse(JSON.stringify(this.investmentsLayer.style_web));

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.investmentsLayer.id]
                ? this.layersFilters[this.investmentsLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, this.investmentsLayer.geometry_type, true, id),
        renderMode: 'hybrid',
      });
      const defaultStyle = {
        datasource: this.investmentsDataSource,
        name: this.$i18n.t('default.defaultStyle'),
        isDefault: true,
        style: style,
      };
      this.setCurrentStyle({ dataSource: this.investmentsDataSource, style: defaultStyle });
      this.setDefaultStyle({ dataSource: this.investmentsDataSource, style: defaultStyle });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      this.setLoaded({ dataSource: this.investmentsDataSource, value: true });
    },
    async setInvestmentsStyle(style) {
      const { id, geometry_type } = this.investmentsLayer;
      const layersArray = this.getLayerById('layers').getLayers().getArray();
      const layer = layersArray.find(layer => layer.get('id') === this.investmentsLayerId);
      layer.setStyle(f => this.getFeatureStyle(f, style.style, geometry_type, false, id));
    },
  },
  mounted() {
    this.$root.$on('investmentsStyleChanged', this.setInvestmentsStyle);
  },
};
