<template>
  <data-input v-bind="inputBind" v-model="computedValue" />
</template>
<script>
import { call, get } from 'vuex-pathify';
import { debounce } from 'lodash';

export default {
  name: 'DataInputFilterExpressionRelation',
  components: {
    DataInput: () => import('@/components/DataInput'),
  },
  props: {
    value: {
      default: null,
    },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    relation: {
      type: Object,
      required: true,
    },
    isEditingOn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    layers: get('layers/layers'),
    dataSources: get('layers/metadata'),
    geometryAttribute() {
      return (
        this.dataSources[this.layers[this.$attrs.layerId]?.data_source_name]?.attributes_schema.geometry_name || ''
      );
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    inputBind() {
      return {
        loading: this.loading,
        readonly: this.loading,
        ...this.$props,
        ...this.$attrs,
        items: this.expressionRelationItems,
        dataType: { ...this.$attrs.dataType, name: 'select' },
      };
    },
    relationDataSource() {
      return this.relation?.data_source;
    },
    relationFilterExpression() {
      return this.relation?.filter_expression;
    },
    attributesNames() {
      return [...Object.keys(this.values), this.geometryAttribute];
    },
  },
  data: () => ({
    expressionRelationItems: [],
    loading: false,
    onUpdateAttributes: [],
  }),
  methods: {
    getDatasourceRelationValuesMapping: call('attributesSchema/getDatasourceRelationValuesMapping'),
    getOnUpdateAttributesWatch(expression = this.relationFilterExpression, rxp = /{{([^}]+)}}/g) {
      const queryVars = [];
      let curMatch;
      while ((curMatch = rxp.exec(expression))) queryVars.push(curMatch[1]);
      this.onUpdateAttributes = queryVars.filter(queryVar => this.attributesNames.includes(queryVar));
      this.$watch(
        vm => {
          return vm.onUpdateAttributes.reduce((total, current) => {
            if (current === this.geometryAttribute) total[current] = this.$attrs.geometry;
            else total[current] = vm.values[current];
            return total;
          }, {});
        },
        (nV, oV) => {
          if (JSON.stringify(oV || '') === JSON.stringify(nV || '')) return;
          if (oV && this.isEditingOn) this.computedValue = null;
          if (Object.values(nV).every(value => !!value || value === 0 || value === false))
            this.getExpressionRelationItems();
          else this.expressionRelationItems = [];
        },
        {
          deep: true,
          immediate: true,
        }
      );
    },
    async getExpressionRelationItems(expression = this.relationFilterExpression, vars = this.onUpdateAttributes) {
      this.loading = true;
      for (const queryVar of vars) {
        const queryVarValue =
          queryVar === this.geometryAttribute ? JSON.stringify(this.$attrs.geometry) : this.values[queryVar];
        expression = expression.replace(new RegExp('{{' + queryVar + '}}', 'gi'), queryVarValue);
      }
      try {
        const r = await this.getDatasourceRelationValuesMapping({
          datasource: this.relationDataSource,
          payload: { filter_expression: JSON.parse(expression) },
        });
        this.expressionRelationItems =
          r?.[this.relationDataSource]?.[this.relation?.attribute]?.[this.relation?.representation] || [];
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getExpressionRelationItems = debounce(this.getExpressionRelationItems, 500);
    this.getOnUpdateAttributesWatch();
  },
};
</script>
