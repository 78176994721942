import congestionAnalysis from '@/mixins/congestionAnalysis';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

import { get, sync } from 'vuex-pathify';

export default {
  mixins: [congestionAnalysis],
  computed: {
    terminalsVisible: sync('congestions/terminalsVisible'),
    analysisResultManoles: get('congestions/analysisResult@manholes'),
  },
  methods: {
    deleteCongestionResultLayer(layerId) {
      const layersArray = this.getLayerById('congestionResultLayers').getLayers().getArray();
      const layersIds = layersArray.map(l => l.get('id'));
      const index = layersIds.indexOf(layerId);
      if (index >= 0) {
        layersArray.splice(index, 1);
        this.map.updateSize();
      }
    },
    async toggleCongestionResultLayer(layer) {
      const { data_source_name, id, type, name, style, opacity, visible, geometry_type, filter_expression, mvtId } =
        layer;
      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity,
        data_source_name,
        id,
        type,
        name,
        isSpecial: true,
        visible,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${mvtId}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, mvtId, {
              filters: filter_expression,
              styleAttributes,
            });
          },
        }),
        zIndex: id.includes('manholes') ? 100 : 99,
        style: f => {
          return id.includes('manholes')
            ? [
                this.getFeatureStyle(f, style, geometry_type, false, mvtId),
                this.getCongestionManholesText({
                  value: this.analysisResultManoles[f.get('id')],
                  labelsStyle: style.labels,
                }),
              ]
            : this.getFeatureStyle(f, style, geometry_type, false, mvtId);
        },
        renderMode: 'hybrid',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('congestionResultLayers').getLayers().push(mvtLayer);
    },
    toggleCongestionResultLayers(value, selectedLayers = null) {
      if (selectedLayers) this.sewerLayersIdsMixinProxy = JSON.parse(JSON.stringify(selectedLayers));
      if (!value) {
        const layer = this.getLayerById('congestionResultLayers').getLayers();
        if (layer) {
          layer.clear();
        }
        return;
      }
      for (const layer of this.congestionProject.layers) {
        this.deleteCongestionResultLayer(layer.id);
        this.toggleCongestionResultLayer(layer);
      }
    },
  },
  watch: {
    terminalsVisible(nV) {
      this.getLayerById('congestionResultLayers')
        .getLayers()
        .getArray()
        .find(layer => layer.get('id') === 'terminals_in_range')
        .setVisible(nV);
    },
  },
  mounted() {
    this.$root.$off('toggleCongestionResultLayers');
    this.$root.$on('toggleCongestionResultLayers', this.toggleCongestionResultLayers);
  },
};
