import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  digitizationTypes: [],
};

const mutations = {
  ...make.mutations(state),
  SET_DIGITIZATION_TYPES(state, types) {
    state.digitizationTypes = Object.entries(types).reduce((acc, [value, text]) => [...acc, { value, text }], []);
  },
};

const actions = {
  async getDigitizationTypes({ commit }) {
    const r = await api.get('automatic_digitization');
    commit('SET_DIGITIZATION_TYPES', r.data.data);
  },
  async getDigitizationResults(store, data) {
    const { digitizationType, payload } = data;
    const r = await api.post(`automatic_digitization/${digitizationType}`, payload, { params: { background: true } });
    return r.data.tid;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
